<template>
  <div>
    <Header active_tab="assessment" />
    <div
      @click="downloadResult"
      v-if="!user.data.admin"
      style="position: absolute; right: 100px"
      title="Download Report Card"
    >
      <span
        class="iconify float-right"
        data-icon="akar-icons:download"
        data-inline="false"
        data-width="30px"
        data-height="30px"
      ></span>
    </div>
    <div class="analysis-route-container">
      <router-link
        tag="div"
        active-class="active"
        class=" brief"
        :to="{ name: 'Brief', query: { test_id, page_id, user_test_id } }"
      >
        <div class="brief-link">BriefAnalysis</div>
      </router-link>

      <router-link
        tag="div"
        active-class="active"
        class="detailed"
        :to="{ name: 'Detailed', query: { test_id, page_id, user_test_id } }"
      >
        <div class=" detailed-link">DetailedAnalysis</div>
      </router-link>
    </div>

    <router-view />
  </div>
</template>

<script>
import Header from "@/components/Header";
import router from "@/router";
import store from "@/store";
import { mapGetters } from "vuex";
import { getTest, testStats, testTakers, questions } from "@/Utils/global";
import axios from "axios";

export default {
  name: "MyResponse",
  components: { Header },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  mounted() {
    this.user_test_id = this.$route.query.user_test_id;
    this.test_id = this.$route.query.test_id;
    this.page_id = this.$route.query.page_id;

    if (!this.user.data.admin) this.user_test_id = store.getters.user.data.uid;

    this.openBrief();
    this.fetchPdfData();

    getTest(this.page_id, this.test_id).then(test => {
      this.total = test.data().total;
      this.title = test.data().title;
      this.startTime = test.data().start.seconds;
    });

    testStats(this.page_id, this.test_id).then(doc => {
      if (doc.exists) {
        let res = doc.data();
        let li = [];
        li = res.score;
        let num = li.length;
        this.rank = num;
        this.avg = Math.round(res.average * 100) / 100;

        testTakers(this.page_id, this.test_id, this.user_test_id).then(doc => {
          let temp = doc.data();
          this.studentName = temp.name;

          li.sort(function(a, b) {
            return b - a;
          });
          this.rank = li.indexOf(temp.score, 0) + 1;
          var M = li.lastIndexOf(temp.score, 0) + 1;
          var R = M - this.rank;

          M = num - M;
          this.percentile = Math.round(((M + 0.5 * R) / num) * 10000) / 100;
        });
      }
    });
    store.state.loading = false;
  },
  data() {
    return {
      test_id: "",
      page_id: "",
      total: 0,
      title: "",
      startTime: 0,
      avg: 0,
      percentile: 0,
      correctAnswer: [],
      sectionArray: [],
      responses: [],
      remark: "",
      rating: "",
      studentName: "",
      subjectiveQuestion: [],
      rank: 0,
      user_test_id: ""
    };
  },
  methods: {
    openBrief() {
      let user_test_id = this.$route.query.user_test_id;
      let test_id = this.$route.query.test_id;
      let page_id = this.$route.query.page_id;

      router.push({
        name: "Brief",
        query: {
          test_id,
          user_test_id,
          page_id
        }
      });
    },
    async fetchPdfData() {
      let isAdmin = this.user.data.admin;
      if (!this.user.data.admin) {
        let uid = store.state.user.data.uid;

        //Response
        let d = await testTakers(this.page_id, this.test_id, uid);

        let data = d.data();

        // console.log(data);
        this.studentName = data.name;
        //correct answer
        let correctAnswer = await questions(this.page_id, this.test_id);
        this.correctAnswer = correctAnswer.docs.map(doc => doc.data());
        this.correctAnswer = this.correctAnswer.sort(this.sort_questions);

        let questionId = {};
        this.correctAnswer.forEach((data, index) => {
          questionId[data.id] = index;
        });

        //section
        let sections = await getTest(this.page_id, this.test_id);

        this.remark = data.remark;
        this.rating = data.performanceRating;

        this.responses = data["responses"];

        this.sectionArray = sections.data().sections;
        let sectionId = {};
        this.sectionArray.forEach((data, index) => {
          data["sNo"] = index + 1;
          data["totalQuestions"] = 0;
          data["attempted"] = 0;
          data["totalMarks"] = 0;
          data["marks"] = 0;
          data["notEvaluated"] = 0;
          sectionId[data.section_name] = index;
        });
        correctAnswer.docs.forEach(answer => {
          let data = answer.data();

          let section = data.section;

          this.sectionArray[sectionId[section]]["totalMarks"] += data.marks;
          if (!("correct" in answer.data())) {
            this.subjectiveQuestion.push(answer.data());

            this.sectionArray[sectionId[section]]["totalQuestions"]++;
            return;
          }
          let marks = this.responses[questionId[data.id]].marks;
          let response = this.responses[questionId[data.id]].response;

          this.sectionArray[sectionId[section]]["totalQuestions"]++;

          if (data.marks === marks) {
            this.sectionArray[sectionId[section]]["marks"] += data.marks;

            this.score += data.marks;
          }

          if (response !== -1 && response != null)
            this.sectionArray[sectionId[section]]["attempted"]++;
        });

        this.sectionArray.forEach((data, index) => {
          this.sectionArray[index]["percentage"] = parseFloat(
            ((data.marks / data.totalMarks) * 100).toFixed(2)
          );
        });

        if (data.reviewed === true) {
          this.subjectiveQuestion.forEach(question => {
            if (
              this.responses[questionId[question.id]].response != null ||
              this.responses[questionId[question.id]].response != -1
            )
              this.sectionArray[sectionId[question.section]]["attempted"]++;

            this.sectionArray[sectionId[question.section]]["marks"] +=
              data.responses[questionId[question.id]].marks;
          });
        }
      }
    },
    sort_questions(a, b) {
      if (parseInt(a.id) < parseInt(b.id)) return -1;
      else return 1;
    },
    downloadResult() {
      console.log("This will donwload result");

      var utcSeconds = this.startTime;
      var d = new Date(0); // The 0 there is the key, which sets the date to the epoc
      d.setUTCSeconds(utcSeconds);

      var dd = {
        content: [
          { text: this.studentName, style: "headerName" },
          { text: "Test Report", style: "header" },
          ` This is a brief test report for the ${this.title} happened on ${d}\n  `,

          {
            text: "\nMarks Obtained:",
            style: "subheader"
          },
          {
            text: 0
          },
          {
            text: "\nPercentile:",
            style: "subheader"
          },
          {
            text: this.percentile
          },
          {
            text: "\nAvg Score:",
            style: "subheader"
          },
          {
            text: this.avg
          },
          { text: "Section Wise Score", style: "subheader" },

          {
            style: "sectionWise",
            table: {
              body: [
                [
                  { text: "Sno", bold: true },
                  { text: "Section Name", bold: true },
                  { text: "Total Questions", bold: true },
                  { text: "Attempted", bold: true },
                  { text: "Total Marks", bold: true },
                  { text: "Marks Obtained", bold: true },
                  { text: "Percentage", bold: true }
                ]
              ]
            },
            layout: {
              fillColor: function(rowIndex, node, columnIndex) {
                return rowIndex % 2 === 1 ? "#CCCCCC" : null;
              }
            }
          },
          {
            text: `Teacher Review: ${
              this.remark ? this.remark : "Not Reviewed"
            }`
          },
          {
            text: `Rating :${this.rating ? this.rating : "Not Rated"}`
          }
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 10]
          },
          subheader: {
            fontSize: 16,
            bold: true,
            margin: [0, 10, 0, 5]
          },
          sectionWise: {
            margin: [0, 5, 0, 15]
          },
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: "black"
          },
          opacity: {
            opacity: 0.5,
            italics: "true"
          },
          headerName: {
            fontSize: "30",
            margin: [0, 20, 20, 20],
            underLine: "true"
          }
        },
        defaultStyle: {
          // alignment: 'justify'
        }
      };

      this.sectionArray.forEach(data => {
        dd.content[4].text += data["marks"];
        var row = [
          data["sNo"],
          data["section_name"],
          data["totalQuestions"],
          data["attempted"],
          data["totalMarks"],
          data["marks"],
          ((data["marks"] / data["totalMarks"]) * 100).toFixed(2)
        ];
        dd.content[10].table.body.push(row);
      });
      dd.content[4].text += "/" + this.total;
      var generatedPDF = pdfMake.createPdf(dd);
      generatedPDF.download();
    }
  }
};
</script>

<style scoped>
.response-container {
  /*width: 100vw;*/
}

.response-row {
  margin-left: 10px;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: black;
  padding: 5px;
}

#nav a.router-link-exact-active {
  color: white;
}

.active {
  background-color: #337ab7;
}

.detailed-link,
.brief-link {
  display: table;
  margin: 10px auto;
}

.analysis-route-container {
  display: flex;
  border: 1px solid black;
  margin: 50px 100px 20px;
}

.brief {
  width: 50% !important;

  /*background-color: #337ab7;*/
}

.detailed {
  width: 50% !important;
}

@media all and (max-width: 550px) {
  .analysis-route-container {
    width: 95vw;
    margin-left: 10px;
    margin-right: 10px;
  }

  .brief-link {
    width: 40vw;
  }
}
</style>
